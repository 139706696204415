.App {
    text-align: center;
}

/*to modal*/
.modal1095w {
    min-width: 1160px;
}

.dbClientImage {
    background-image: url("./app/image/floor.jpg");
    background-position: right top;
    background-attachment: fixed;
    background-size: cover;
    min-height: 845px;
}

table {
    background-color: white;
}
