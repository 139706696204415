.textCenter {
    text-align: center;
}
.textRight {
    text-align: right;
}
.textLeft {
    text-align: left;
}

.navBarRadius {
    border-radius: 15px 15px 0px 0px;
}

.colorInactive {
    background-color: rgb(240, 220, 176);
}
